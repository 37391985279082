import { omit } from 'lodash';

import config from '../universal/config';
import {
  fetchAll,
  fetchAllJSON,
  fetchAllWithCredentials,
  fetchJSONWithCredentials,
} from '../universal/fetch';

function getServices(query) {
  const base = `${config.get().ManagementBaseURL}/services`;
  const URL = query ? `${base}?${query}` : base;

  return fetchJSONWithCredentials(URL);
}

function getAllServiceAccounts(serviceName) {
  let url = `${config.get().ManagementBaseURL}/accounts`;

  if (serviceName) {
    url += `?service_name=${serviceName}`;
  }

  return fetchJSONWithCredentials(url);
}

function getServiceAccounts(groupId, serviceNames) {
  let url = `${config.get().ManagementBaseURL}/groups/${groupId}/accounts`;

  if (serviceNames) {
    const query = serviceNames.map((s) => `service[]=${s}`).join('&');
    url += `?${query}`;
  }

  return fetchJSONWithCredentials(url);
}

function getWidgetsForService(serviceId) {
  const url = `${config.get().ManagementBaseURL}/accounts/${serviceId}/widgets`;

  return fetchJSONWithCredentials(url);
}

function getService(serviceId) {
  const URL = `${config.get().ManagementBaseURL}/services/${serviceId}`;

  return fetchJSONWithCredentials(URL);
}

function getServiceByName(serviceName) {
  const URL = `${config.get().ManagementBaseURL}/services/name/${serviceName}`;

  return fetchJSONWithCredentials(URL);
}

function getServiceWidgetTypes(serviceName) {
  const URL = `${
    config.get().ManagementBaseURL
  }/services/name/${serviceName}/widget_types`;

  return fetchJSONWithCredentials(URL);
}

function getWidgetType(widgetTypeIdOrKey) {
  const URL = `${
    config.get().ManagementBaseURL
  }/widget_types/${widgetTypeIdOrKey}`;

  return fetchJSONWithCredentials(URL);
}

function deleteServiceAccount(groupId, serviceId, deleteAssociatedWidgets) {
  const params = deleteAssociatedWidgets ? '?delete_associated_widgets=1' : '';
  const URL = `${
    config.get().ManagementBaseURL
  }/groups/${groupId}/accounts/${serviceId}${params}`;
  return fetchJSONWithCredentials(URL, { method: 'DELETE' });
}

function createServiceAccount(integrationSlug, accountData) {
  const URL = `${config.get().ManagementBaseURL}/accounts`;
  const { user, password, name } = accountData;

  return fetchJSONWithCredentials(URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      integration_slug: integrationSlug,
      extra: omit(accountData, 'user', 'password', 'name'),
      basic_auth: {
        user,
        password,
      },
      name,
    }),
  }).catch((err) => {
    if (err.status === 409) {
      const message =
        err.user_message || 'The account details entered could not be verified';
      throw new Error(message);
    }
    throw new Error('An error occurred saving the account details');
  });
}

function getDashboard(dashboardId) {
  return fetchJSONWithCredentials(
    `${config.get().ManagementBaseURL}/dashboards/${dashboardId}`,
  );
}

function getAllSharingLoops() {
  return fetchJSONWithCredentials(
    `${config.get().ManagementBaseURL}/sharing_loops`,
  );
}

function getUser() {
  return fetchJSONWithCredentials(`${config.get().ManagementBaseURL}/user`);
}

function patchUser(body) {
  const URL = `${config.get().ManagementBaseURL}/user`;

  return fetchJSONWithCredentials(URL, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
}

function putUser(body) {
  const URL = `${config.get().ManagementBaseURL}/user`;

  return fetchJSONWithCredentials(URL, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
}

function putOrganization(id, body) {
  const URL = `${config.get().ManagementBaseURL}/organizations/${id}`;

  return fetchJSONWithCredentials(URL, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
}

const deleteOrganizationWithFeedback = (feedback) => {
  return fetchAllWithCredentials(`/organizations/delete_with_feedback`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      why_cancel: feedback.whyCancel,
      what_you_using_now: feedback.whatYouUsingNow,
    }),
  });
};

function getPlans() {
  return fetchJSONWithCredentials(
    `${config.get().ManagementBaseURL}/billing/plans`,
  );
}

function getOrganization(orgId) {
  return fetchJSONWithCredentials(
    `${config.get().ManagementBaseURL}/organizations/${orgId}`,
  );
}

function startTrial() {
  const URL = `${config.get().ManagementBaseURL}/billing/start-pro-trial`;

  return fetchJSONWithCredentials(URL, {
    method: 'PUT',
  });
}

function getPairingCode() {
  const URL = `${config.get().ManagementBaseURL}/pairing_codes`;

  return fetchAllJSON(URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}

function getPairingStatus(code) {
  const URL = `${config.get().ManagementBaseURL}/pairing_codes/${code}`;

  return fetchAll(URL, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}

function exchangeDeviceToken(token) {
  const URL = `/tv/exchange/${token}`;

  return fetchJSONWithCredentials(URL);
}

function getLogoUploadLink(dashboardId, filename) {
  const URL = `${
    config.get().ManagementBaseURL
  }/dashboards/${dashboardId}/logos`;
  const body = JSON.stringify({ filename });
  return fetchJSONWithCredentials(URL, { method: 'POST', body });
}

function createDashboard(groupId, dashboard) {
  const URL = `${config.get().ManagementBaseURL}/groups/${groupId}/dashboards`;
  const body = JSON.stringify(dashboard);
  return fetchJSONWithCredentials(URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body,
  });
}

function createWidget(dashboardId, widgetType, data) {
  const URL = `${
    config.get().ManagementBaseURL
  }/dashboards/${dashboardId}/widgets`;

  return fetchJSONWithCredentials(URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      layout: {
        // All utility widgets use the same default size. If we use this function for
        // other platforms we might have to call `getDefaultSize` to get the size.
        pixel_width: 230,
        pixel_height: 230,
      },
      widget_type: widgetType,
      config: data,
    }),
  });
}

function getPeople() {
  const URL = `${config.get().ManagementBaseURL}/people`;

  return fetchJSONWithCredentials(URL);
}

function deleteWidget(dashboardId, widgetKey) {
  const URL = `${
    config.get().ManagementBaseURL
  }/dashboards/${dashboardId}/widgets/${widgetKey}`;

  return fetchJSONWithCredentials(URL, {
    method: 'DELETE',
  });
}

// The endpoint will create a annual/monthly subscription in Geckoboard and Recurly.
function checkoutPlan({
  planCode,
  recurlyToken,
  threeDSecureToken,
  subscriptionPeriod,
  billingEmail,
}) {
  const URL = `${config.get().ManagementBaseURL}/billing/checkout`;

  return fetchJSONWithCredentials(URL, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      plan_code: planCode,
      recurly_token: recurlyToken,
      recurly_three_d_secure_token: threeDSecureToken,
      subscription_period: subscriptionPeriod,
      billing_email: billingEmail,
    }),
  });
}

function getBillingStatus() {
  const URL = `${config.get().ManagementBaseURL}/billing/status`;

  return fetchJSONWithCredentials(URL, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}

function regenerateApiKey() {
  const URL = `${config.get().ManagementBaseURL}/user/regenerate_api_key`;

  return fetchJSONWithCredentials(URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}

function changePlanOnSubscription({ planCode, subscriptionPeriod }) {
  const URL = `${config.get().ManagementBaseURL}/billing/subscription`;

  return fetchJSONWithCredentials(URL, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      plan_code: planCode,
      subscription_period: subscriptionPeriod,
    }),
  });
}

function getInvoices() {
  const URL = `${config.get().ManagementBaseURL}/billing/invoices`;

  return fetchJSONWithCredentials(URL);
}

export {
  changePlanOnSubscription,
  checkoutPlan,
  createDashboard,
  createServiceAccount,
  createWidget,
  deleteOrganizationWithFeedback,
  deleteServiceAccount,
  deleteWidget,
  exchangeDeviceToken,
  getAllServiceAccounts,
  getAllSharingLoops,
  getBillingStatus,
  getDashboard,
  getInvoices,
  getLogoUploadLink,
  getOrganization,
  getPairingCode,
  getPairingStatus,
  getPeople,
  getPlans,
  getService,
  getServiceAccounts,
  getServiceByName,
  getServices,
  getServiceWidgetTypes,
  getUser,
  getWidgetsForService,
  getWidgetType,
  patchUser,
  putOrganization,
  putUser,
  regenerateApiKey,
  startTrial,
};
